import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { DRAFTS } from 'DataLayer/endpoints';

export async function get() {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };
  const { data } = await axios.get(DRAFTS, config);

  return data;
}
