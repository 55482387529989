import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { useDraftsByWorkspaceId } from 'Hooks/data/drafts/useDrafts';

import styles from './WorkSpaceDraft.module.css';

const WorkSpaceDraft = ({ workspaceId }) => {
  const drafts = useDraftsByWorkspaceId(workspaceId);
  if (!drafts.length) {
    return null;
  }
  return (
    <small>
      <strong className={styles.draft}>
        <FormattedMessage
          id="WorkspaceDraft"
          values={{ number: drafts.length }}
        />
      </strong>
    </small>
  );
};

WorkSpaceDraft.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default WorkSpaceDraft;
