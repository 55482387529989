import { useEffect, useRef, useState } from 'react';
import { get as getNewVersionAvailable } from 'DataLayer/NewVersionAvailable/get';
import { getNewVersionPollInteral } from 'config/config';

const DELAY = getNewVersionPollInteral();

/*
  See docs/faq.md for information on how this file is used
*/
function useNewVersionAvailable() {
  const previousVersion = useRef();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    const checkForNewVersion = () => {
      setTimeout(async () => {
        try {
          const data = await getNewVersionAvailable();

          if (previousVersion.current && previousVersion.current !== data) {
            setNewVersionAvailable(true);
          } else {
            previousVersion.current = data;
            checkForNewVersion();
          }
        } catch (e) {}
      }, DELAY);
    };

    checkForNewVersion();
  }, []);

  return newVersionAvailable;
}

export default useNewVersionAvailable;
