import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';

import { showCreateMeetingModal } from 'store/Modals/actions';
import { getNumberOfActiveMeetings } from 'store/Meeting/selectors';
import { getLatestChannelId, getLatestWorkspaceId } from 'store/UI/selectors';

import { useWorkspace } from 'Hooks/data/workspace/useWorkspace';

import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { CameraIcon } from 'Components/Icons/WeTeamIcon';
import ActiveMeetingBeacon from 'Components/Meeting/ActiveMeetingBeacon';

import AppAreaButton from './AppAreaButton';

import styles from './MeetingRoomButton.module.css';

export function MeetingRoomButton({
  params: { workspaceId, spaceId },
  sidebarOpen,
  onShowCreateMeetingModal,
}) {
  const workspace = useWorkspace(workspaceId, spaceId);
  const latestWorkspaceId = useSelector(getLatestWorkspaceId);
  const latestChannelId = useSelector(getLatestChannelId);

  const numberOfActiveMeetings = useSelector(getNumberOfActiveMeetings);

  const onMeetingRoomClick = () => {
    onShowCreateMeetingModal(
      workspace.Id || latestWorkspaceId,
      spaceId || latestChannelId,
      numberOfActiveMeetings > 0
    );
  };

  return (
    <AppAreaButton
      onClick={onMeetingRoomClick}
      icon={
        <>
          <span className={styles.meetingBeacon}>
            <ActiveMeetingBeacon width={16} height={16} />
          </span>
          <WeTeamIcon Icon={CameraIcon} color="white" link={false} />
        </>
      }
      name={<FormattedMessage id="AppNavigation.MeetingRoom" />}
      tooltip={<FormattedMessage id="AppNavigation.MeetingRoom.Tooltip" />}
      sidebarOpen={sidebarOpen}
    />
  );
}

MeetingRoomButton.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    spaceId: PropTypes.string,
    workspaceId: PropTypes.string,
  }).isRequired,
  onShowCreateMeetingModal: PropTypes.func.isRequired,
};

export default withRouter(
  connect(undefined, {
    onShowCreateMeetingModal: showCreateMeetingModal,
  })(MeetingRoomButton)
);
