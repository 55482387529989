import { createSelector } from 'reselect';

const NETWORK_STATUS_CHANGED = 'CONNECTIVITY/NETWORK_STATUS_CHANGED';
const WEBSOCKET_STATUS_CHANGED = 'CONNECTIVITY/WEBSOCKET_STATUS_CHANGED';
const DRAFT_DATA_CHANGED = 'CONNECTIVITY/DRAFT_DATA_CHANGED';
const DRAFT_DELETED = 'CONNECTIVITY/DRAFT_DELETED';
const TAG_DATA_CHANGED = 'CONNECTIVITY/TAG_DATA_CHANGED';
const NOTE_DATA_CHANGED = 'CONNECTIVITY/NOTE_DATA_CHANGED';
const REAL_TIME_NOTE_DATA_CHANGED = 'CONNECTIVITY/REAL_TIME_NOTE_DATA_CHANGED';

export const networkStatusChanged = online => ({
  type: NETWORK_STATUS_CHANGED,
  online,
});

export const websocketStatusChanged = connected => ({
  type: WEBSOCKET_STATUS_CHANGED,
  connected,
});

export const draftDataChanged = payload => ({
  type: DRAFT_DATA_CHANGED,
  payload,
});

export const draftDeleted = payload => ({
  type: DRAFT_DELETED,
  payload,
});

export const tagDataChanged = payload => ({
  type: TAG_DATA_CHANGED,
  payload,
});

export const noteDataChanged = payload => ({
  type: NOTE_DATA_CHANGED,
  payload,
});

export const realTimeNoteDataChanged = payload => ({
  type: REAL_TIME_NOTE_DATA_CHANGED,
  payload,
});

export const getWebsocketConnected = state =>
  state.connectivity.websocketConnected;

export const getDraftData = state => state.connectivity.draftData;

export const getDeletedDraft = state => state.connectivity.deletedDraft;

export const getTagData = state => state.connectivity.tagData;

export const getNoteData = state => state.connectivity.noteData;

export const getRealTimeNoteChange = createSelector(
  [state => state.connectivity.realTimeNoteChange],
  realTimeNoteChange => realTimeNoteChange
);

const initialState = {
  online: true,
  websocketConnected: false,
  tagData: {},
  draftData: {},
  deletedDraft: {},
  noteData: {},
  realTimeNoteChange: null,
};

export default function connectivityReducer(state = initialState, action) {
  switch (action.type) {
    case NETWORK_STATUS_CHANGED: {
      return {
        ...state,
        online: action.online,
      };
    }
    case WEBSOCKET_STATUS_CHANGED: {
      return {
        ...state,
        websocketConnected: action.connected,
      };
    }
    case DRAFT_DATA_CHANGED: {
      return {
        ...state,
        draftData: action.payload,
      };
    }
    case DRAFT_DELETED: {
      return {
        ...state,
        deletedDraft: action.payload,
      };
    }
    case TAG_DATA_CHANGED: {
      return {
        ...state,
        tagData: action.payload,
      };
    }
    case NOTE_DATA_CHANGED: {
      return {
        ...state,
        noteData: action.payload,
      };
    }
    case REAL_TIME_NOTE_DATA_CHANGED: {
      return {
        ...state,
        realTimeNoteChange: action.payload,
      };
    }
    default:
      return state;
  }
}
