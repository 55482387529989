import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loadSpace from 'store/Spaces/loadSpace';
import { makeGetSpaceById } from 'store/Spaces/selectors';

export const useChannel = (channelId, fetchIfMissing) => {
  const [error, setError] = useState(null);
  const getSpaceById = useMemo(makeGetSpaceById, []);
  const dispatch = useDispatch();

  const channel = useSelector(state => {
    return getSpaceById(state, channelId);
  });

  useEffect(() => {
    if (!channel.Id && fetchIfMissing) {
      dispatch(loadSpace(channelId)).catch(setError);
    }
  }, [channelId, dispatch, fetchIfMissing, channel]);

  return { channel, error };
};
