import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { Loader } from 'semantic-ui-react';

import { THUMBNAIL_SIZES, getThumbnailUrl } from 'DataLayer/Thumbnail/get';

import useGetProtectedFileBlobUrl from 'Hooks/files/useGetProtectedFileBlobUrl';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';

import ImageLoader from 'Components/Avatar/ImageLoader';
import PreviewError from 'Components/Chat/Previewers/PreviewError';

import './PreviewThumbnail.scss';

function PreviewThumbnail({
  resourceId,
  fileDeleted,
  openPreview,
  resourceName,
  resourceHeight,
  resourceType,
  resourceSize,
}) {
  const isBackupMode = useIsBackupMode();
  const getUrl = () => {
    const url = getThumbnailUrl(
      resourceId,
      THUMBNAIL_SIZES[350],
      !isBackupMode
    );
    /* 
      if the file was deleted, changing the url will cause
      the image to be fetched again, and will result in an error, 
      allowing us to show the "file was deleted" message
    */
    return fileDeleted ? `${url}&force-update` : url;
  };

  const {
    blobUrl,
    fetchFile,
    error: blobError,
    loading: blobLoading,
  } = useGetProtectedFileBlobUrl(getUrl(), {
    asBlob: true,
    cacheEnabled: true,
  });

  const onWaypointEntered = () => {
    if (!blobUrl) {
      fetchFile();
    }
  };

  /*
      - wait until the component is on screen before loading the image
      - before the image loads, we show a placeholder.
      - to prevent the chat scroll jumping when the image appears, the height of the placeholder before loading occurs should be the same height as the image
      - if the image fails, the error should not have the same height as the placeholder.
    */
  return (
    <Waypoint onEnter={onWaypointEntered}>
      <div>
        <ImageLoader src={blobUrl}>
          {(loading, error) => {
            if (error || blobError) {
              return (
                <PreviewError
                  onClick={openPreview}
                  className="cursor-pointer"
                />
              );
            }
            return (
              <div className="preview-image" style={{ height: resourceHeight }}>
                {loading || blobLoading || !blobUrl ? (
                  <Loader active inline />
                ) : (
                  <img src={blobUrl} alt={resourceName} onClick={openPreview} />
                )}
              </div>
            );
          }}
        </ImageLoader>
      </div>
    </Waypoint>
  );
}

PreviewThumbnail.propTypes = {
  resourceId: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceSize: PropTypes.number,
  resourceHeight: PropTypes.number,
  openPreview: PropTypes.func.isRequired,
  fileDeleted: PropTypes.bool,
};

export default PreviewThumbnail;
