import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Modal, Header, Button, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import uniqBy from 'lodash/uniqBy';

import { getUserGuid } from 'store/User/selectors';
import { sendInvitationv2 } from 'store/Meeting/Users/sendInvitation';
import { deleteInvitation } from 'store/Meeting/Users/deleteInvitation';
import { getOrderedChatsByWorkspaceId } from 'store/Spaces/selectors';

import useAsyncFn from 'Hooks/useAsyncFn';

import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import CreateMembersMeetingNew from 'Components/Modals/Meeting/CreateMeetingModal/CreateMembersMeetingNew';
import MeetingUserAvatars from 'Components/Meeting/MeetingUserAvatars';
import ParticipantAvatars from 'Components/Meeting/ParticipantAvatars';

const ManageParticipantsModal = ({ meeting, chats, onClose }) => {
  const userGuid = useSelector(getUserGuid);

  const meetingUsers = meeting.users
    .filter(user => user.userId !== userGuid)
    .map(user => {
      return {
        ...user,
        Id: user.userId,
        UserId: user.userId,
        collaboratorUserId: user.userId,
        FirstName: user.firstName,
        LastName: user.lastName,
        Name: `${user.firstName} ${user.lastName}`,
        Email: user.email,
        AvatarUrl: user.avatarUrl,
        Color: user.color,
        Presence: user.presence,
        fromApi: true,
      };
    });

  const chatsFinalUnique = uniqBy(
    chats.concat(meetingUsers),
    'collaboratorUserId'
  );

  const dispatch = useDispatch();
  const [selectedChats, setSelectedChats] = useState(
    meeting.users
      .filter(user => user.invite)
      .map(user =>
        chatsFinalUnique.find(chat => chat.collaboratorUserId === user.userId)
      )
      .filter(user => user)
  );

  const [sendInvitationRequest, onSendInvitation] = useAsyncFn(
    async chat => {
      await dispatch(sendInvitationv2(meeting.roomId, chat));
    },
    [meeting]
  );

  const [deleteInvitationRequest, onDeleteInvitation] = useAsyncFn(
    async userId => {
      await dispatch(deleteInvitation(meeting.roomId, userId));
    },
    [meeting]
  );

  const usersToRemove = useMemo(() => {
    //find invited users who are no longer in selectedChats
    const invitedUserIds = meeting.users
      .filter(user => user.invite)
      .map(user => user.userId);

    //find selectedChats who are not in invited users
    const selectedChatIds = selectedChats.map(chat => chat.collaboratorUserId);

    return invitedUserIds.filter(
      invitedUserId => !selectedChatIds.includes(invitedUserId)
    );
  }, [meeting.users, selectedChats]);

  const usersToInvite = useMemo(() => {
    //find invited users who are no longer in selectedChats
    const invitedUserIds = meeting.users
      .filter(user => user.invite)
      .map(user => user.userId);

    return selectedChats.filter(
      selectedChat => !invitedUserIds.includes(selectedChat.collaboratorUserId)
    );
  }, [meeting.users, selectedChats]);

  const onUpdate = async () => {
    for (const userId of usersToRemove) {
      await onDeleteInvitation(userId);
    }
    for (const user of usersToInvite) {
      await onSendInvitation(user);
    }
  };

  return (
    <Modal size="tiny" open onClose={onClose}>
      <Header>
        <FormattedMessage id="ManageParticipants.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onClose} />
        </div>
      </Header>
      <Modal.Content>
        <p>
          <MeetingUserAvatars meeting={meeting} />
        </p>
        <p>
          <ParticipantAvatars meeting={meeting} />
        </p>
        <ErrorBoundary>
          <CreateMembersMeetingNew
            defaultWorkspaceId={meeting.workspaceId}
            selectedChats={selectedChats}
            chats={chatsFinalUnique}
            onSelectedChatsChange={setSelectedChats}
          />
        </ErrorBoundary>
        {(sendInvitationRequest.error || deleteInvitationRequest.error) && (
          <Message error>
            <FormattedMessage id="ManageParticipantsModal.Error" />
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div className="modal-buttons">
          <Button
            primary
            onClick={onUpdate}
            loading={
              sendInvitationRequest.loading || deleteInvitationRequest.loading
            }
            disabled={
              sendInvitationRequest.loading ||
              deleteInvitationRequest.loading ||
              (!usersToRemove.length && !usersToInvite.length)
            }
          >
            <FormattedMessage id="ManageParticipantsModal.UpdateButtonText" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

ManageParticipantsModal.propTypes = {
  meeting: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(() => {
  return (state, props) => {
    return {
      chats: getOrderedChatsByWorkspaceId(state, props.meeting.workspaceId),
    };
  };
})(ManageParticipantsModal);
