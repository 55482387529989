import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router';
import get from 'lodash/get';

import { getSpaceLink } from 'pages';

import memberStatus from 'DataLayer/Constants/workspaceMemberStatus';

import SpaceIcon from 'Components/Spaces/SpaceIcon';
import UnreadEventsBadge from './UnreadEventsBadge';
import LastActivity from '../../Spaces/LastActivity';
import { SortableElement } from 'react-sortable-hoc';
import MeetingBeaconByRoom from '../../Meeting/MeetingBeaconByRoom';
import SkeletonLine from 'Views/Skeleton/SkeletonLine';

import styles from './SpaceNode.module.scss';
import ChannelOptions from 'Components/Chat/SpaceChat/ChannelOptions';
import WeTeamIcon, {
  ArrowDown2Icon,
  ShowIcon,
} from 'Components/Icons/WeTeamIcon';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';

function SpaceNode({ space, router }) {
  const selected = router?.params.spaceId === space.Id;
  const ref = useRef(null);
  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [selected]);

  const rowClass = classNames(styles.spaceNode, {
    [styles.selected]: selected,
  });

  const invitationStatus = get(space, 'Status.OtherUserStatus');

  const showEmail =
    invitationStatus === memberStatus.INVITED &&
    space.CollaboratorEmail !== space.Name;
  return (
    <div
      ref={ref}
      className={rowClass}
      onClick={event => {
        event.stopPropagation();
        router.push(getSpaceLink(space.Id));
      }}
    >
      <SpaceIcon space={space} />
      <div className={styles.name} title={space.Name}>
        {!space.Name && <SkeletonLine />}
        {!space.IsDefault && space.Name}
        {space.IsDefault && (
          <div className="flex flex-no-shrink">
            {space.Name}
            <Popup
              basic
              position="top center"
              inverted
              trigger={
                <WeTeamIcon
                  width={17}
                  height={17}
                  className="ml-1 flex-no-shrink"
                  Icon={ShowIcon}
                  link={false}
                />
              }
              content={<FormattedMessage id="SpaceNode.DefaultChannel" />}
              size="mini"
            />
          </div>
        )}
        {showEmail && (
          <div>
            <small>{space.CollaboratorEmail}</small>
          </div>
        )}
        <div className={styles.meta}>
          <small>
            {invitationStatus === memberStatus.INVITED
              ? 'Invitation sent...'
              : space.LastUpdateTimestamp && (
                  <LastActivity
                    date={space.LastUpdateTimestamp}
                    spaceId={space.Id}
                  />
                )}
          </small>
        </div>
      </div>
      <div className={styles.badges}>
        <MeetingBeaconByRoom channelId={space.Id} />
        <UnreadEventsBadge spaceId={space.Id} />
        <div className={styles.menu}>
          <ChannelOptions
            space={space}
            trigger={<WeTeamIcon Icon={ArrowDown2Icon} link={false} />}
          />
        </div>
      </div>
    </div>
  );
}

SpaceNode.propTypes = {
  space: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
    params: PropTypes.shape({
      spaceId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SortableElement(withRouter(memo(SpaceNode)));
