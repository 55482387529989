import React from 'react';
import PropTypes from 'prop-types';
import Draft from 'Components/Drafts/Draft';

function DraftsList({ drafts }) {
  return (
    <>
      {drafts.map(draft => (
        <Draft key={draft.id} draft={draft} />
      ))}
    </>
  );
}

DraftsList.propTypes = {
  drafts: PropTypes.array.isRequired,
};

export default DraftsList;
