import window from './Window';
import application from './Application';
import connectivity from './Connectivity';
import activities from './Activities';
import isTyping from './IsTyping';
import workspaceSearch from './WorkspaceSearch';
//import workgroups from './Workgroups';
import workspaces from './Workspaces';
import publicReducer from './Public/index';
//import members from './Members/index';
import downloads from './Download';
import addressBook from './AddressBook';
import toasts from './Toast';
import ui from './UI';
import uiTemporary from './UITemporary';
import repositories from './Repositories';
import resourceLink from './Link';
import resources from './Resources';
import spaces from './Spaces';
import adapters from './Adapters';
import locale from './Locale';
import load from './Load';
import user from './User';
import subscriptions from './User/Subscription';
import linkedItems from './LinkedItems';
import modals from './Modals';
import comments from './Comments';
import paginate from './Paginate';
import upload from './Upload';
import mediaInfo from './MediaInfo';
import copy from './Copy';
import sorting from './Sorting';
import requests from './Requests';
import entities from './Entities';
import paths from './Paths';
import storeCategories from './Integrations/Categories';
import storeIntegrationApps from './Integrations/IntegrationApps';
import storeTemplates from './Integrations/Templates';
import zaps from './Integrations/Zaps';
import transfers from './Transfers';
import systemNotifications from './Notifications';
import starred from './Starred';
import reminders from './Reminders';
import meeting from './Meeting';
import offer from './Workspaces/Offer';
import audioPlayer from './AudioPlayer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

export default {
  activities,
  adapters,
  addressBook,
  application,
  audioPlayer,
  comments,
  connectivity,
  copy,
  downloads,
  entities,
  isTyping,
  load,
  locale,
  linkedItems,
  mediaInfo,
  meeting,
  modals,
  offer: persistReducer(
    {
      key: 'offer',
      storage: storage,
      whitelist: ['postponed'],
    },
    offer
  ),
  paginate,
  paths,
  public: publicReducer,
  reminders,
  repositories,
  requests,
  resourceLink,
  resources,
  sorting,
  spaces,
  starred,
  storeCategories,
  storeIntegrationApps,
  storeTemplates,
  subscriptions,
  systemNotifications,
  toasts,
  transfers,
  // events,
  ui,
  uiTemporary,
  upload,
  user,
  //workgroups,
  window,
  workspaces,
  workspaceSearch,
  zaps,
};
