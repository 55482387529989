import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { applicationInfoReceived } from 'store/Application/index';

export const PLATFORM_INFORMATION = 'platform-information';

export class ElectronPlatformListener extends React.PureComponent {
  componentDidMount() {
    console.debug(PLATFORM_INFORMATION, window.platformInformation);
    this.props.onApplicationInfoReceived(
      window.platformInformation.isAppStore,
      window.platformInformation.platform
    );
  }

  render() {
    return null;
  }
}

ElectronPlatformListener.propTypes = {
  onApplicationInfoReceived: PropTypes.func.isRequired,
};

export default connect(undefined, {
  onApplicationInfoReceived: applicationInfoReceived,
})(ElectronPlatformListener);
