import { getByWorkspaceId } from 'DataLayer/Workspaces/Members/get';
import { addWorkspaceMembers } from './actions';
import { getWorkspaceMembersInProgress } from 'store/Requests/selectors';
import { setWorkspaceMembersSpacesRequestInProgress } from 'store/Requests/setRequestInProgress';

const loadMembersByWorkspaceId = workspaceId => async (dispatch, getState) => {
  try {
    if (!workspaceId) {
      return;
    }
    const state = getState();
    if (getWorkspaceMembersInProgress(state, workspaceId)) {
      return Promise.resolve();
    }
    dispatch(setWorkspaceMembersSpacesRequestInProgress(workspaceId, true));
    const members = await getByWorkspaceId(workspaceId);
    const membersObject = members.reduce((object, value) => {
      object[value.Id] = value;
      return object;
    }, {});
    dispatch(addWorkspaceMembers(workspaceId, membersObject));
    dispatch(setWorkspaceMembersSpacesRequestInProgress(workspaceId, false));
  } catch (error) {
    dispatch(setWorkspaceMembersSpacesRequestInProgress(workspaceId, false));
    throw error;
  }
};

export default loadMembersByWorkspaceId;
