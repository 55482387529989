import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import orderBy from 'lodash/orderBy';

import { get } from 'DataLayer/Drafts/get';
import { putById } from 'DataLayer/Drafts/put';
import { post } from 'DataLayer/Drafts/post';
import { deleteById } from 'DataLayer/Drafts/delete';

import { getDraftData, getDeletedDraft } from 'store/Connectivity';

const QUERY_KEY = ['drafts'];

const orderDrafts = drafts => orderBy(drafts, d => d.id);

export const useGetDrafts = () =>
  useQuery(QUERY_KEY, get, {
    initialData: [],
    select: orderDrafts,
  });

export const useDraftsByWorkspaceId = workspaceId => {
  const { data } = useGetDrafts();
  return data.filter(draft => draft.workspaceId === workspaceId);
};

export const useDraftByChannelId = channelId => {
  const { data } = useGetDrafts();
  return data.find(draft => draft.channelId === channelId);
};

export const useSaveDraft = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: post,
    onSuccess: async newDraft => {
      queryClient.setQueryData(QUERY_KEY, oldData =>
        insertDraftInCache(oldData, newDraft)
      );
    },
  });
};

export const useUpdateDraft = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putById,
    onSuccess: async newDraft => {
      queryClient.setQueryData(QUERY_KEY, oldData =>
        updateDraftInCache(oldData, newDraft)
      );
    },
  });
};

export const useDeleteDraft = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteById,
    onSuccess: async (_, { id }) => {
      deleteDraftFromCache(queryClient, id);
    },
  });
};

const insertDraftInCache = (drafts, newDraft) => [...drafts, newDraft];

const updateDraftInCache = (drafts, newDraft) =>
  drafts.map(draft => {
    if (draft.id === newDraft.id) {
      return newDraft;
    }
    return draft;
  });

const deleteDraftFromCache = (queryClient, id) => {
  queryClient.setQueryData(QUERY_KEY, oldData =>
    oldData.filter(draft => id !== draft.id)
  );
};

const useWatchDraftDeletedFromWebSocket = () => {
  const queryClient = useQueryClient();

  const deletedDraft = useSelector(getDeletedDraft);

  useEffect(() => {
    deleteDraftFromCache(queryClient, deletedDraft.id);
  }, [deletedDraft, queryClient]);
};

export const useWatchDraftChangesFromWebSocket = () => {
  useWatchDraftDeletedFromWebSocket();
  const queryClient = useQueryClient();

  const newDraft = useSelector(getDraftData);

  useEffect(() => {
    queryClient.setQueryData(QUERY_KEY, oldData => {
      if (oldData.some(d => d.id === newDraft.id)) {
        return updateDraftInCache(oldData, newDraft);
      } else {
        return insertDraftInCache(oldData, newDraft);
      }
    });
  }, [newDraft, queryClient]);
};
