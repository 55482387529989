import { createMeetingUser } from 'DataLayer/Meeting/post';
import { post } from 'DataLayer/Meeting/Users/post';
import { activeMeetingUpdated } from 'store/Meeting';
import {
  getChannelIdByRoomId,
  getIsChannelMeetingByRoomId,
} from 'store/Meeting/selectors';
import { makeGetChatCollaborator } from 'store/Spaces/Collaborators/selectors';

export const sendInvitation = (meetingId, user) => async dispatch => {
  try {
    const meeting = await post(meetingId, user);
    dispatch(activeMeetingUpdated(meeting));
  } catch (ex) {
    throw ex;
  }
};

export const sendInvitationv2 =
  (meetingId, chat) => async (dispatch, getState) => {
    try {
      const state = getState();

      const collaborator = makeGetChatCollaborator()(state, chat.Id);
      const isChannelMeeting = getIsChannelMeetingByRoomId(state, meetingId);
      const channelId = getChannelIdByRoomId(state, meetingId);

      const user = chat.fromApi
        ? createMeetingUser({
            userId: chat.UserId,
            firstName: chat.FirstName,
            lastName: chat.LastName,
            email: chat.Email,
            avatarUrl: chat.AvatarUrl,
            color: chat.Color,
            invite: true,
          })
        : createMeetingUser({
            userId: collaborator.UserId,
            firstName: collaborator.FirstName,
            lastName: collaborator.LastName,
            email: collaborator.Email,
            avatarUrl: collaborator.AvatarUrl,
            color: collaborator.Color,
            channelId: isChannelMeeting ? channelId : chat.Id,
            invite: true,
          });

      const meeting = await post(meetingId, user);
      dispatch(activeMeetingUpdated(meeting));
    } catch (ex) {
      throw ex;
    }
  };
