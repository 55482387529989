import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';

import { useDraftByChannelId } from 'Hooks/data/drafts/useDrafts';

import styles from './LastActivity.module.css';

function LastActivity({ date, spaceId }) {
  const draft = useDraftByChannelId(spaceId);
  if (draft) {
    return (
      <>
        <strong className={styles.draft}>
          {/* eslint-disable react/jsx-no-literals */}
          <FormattedMessage id="Draft" />:
          {/* eslint-enable react/jsx-no-literals */}
        </strong>
        &nbsp;
        {draft.value}
      </>
    );
  }

  const dayDifference = differenceInCalendarDays(
    format(new Date()),
    format(date)
  );

  if (dayDifference < 1) {
    return (
      <FormattedTime
        value={date}
        updateInterval={false}
        minute="2-digit"
        hour="2-digit"
      />
    );
  } else if (dayDifference < 7) {
    return (
      <>
        <FormattedDate value={date} weekday="short" />
        {/* eslint-disable react/jsx-no-literals */}
        &nbsp;@&nbsp;
        {/* eslint-enable react/jsx-no-literals */}
        <FormattedTime
          value={date}
          updateInterval={false}
          minute="2-digit"
          hour="2-digit"
        />
      </>
    );
  }

  return (
    <>
      <FormattedDate
        value={date}
        year="2-digit"
        month="2-digit"
        day="2-digit"
      />
      {/* eslint-disable react/jsx-no-literals */}
      &nbsp;@&nbsp;
      {/* eslint-enable react/jsx-no-literals */}
      <FormattedTime
        value={date}
        updateInterval={false}
        minute="2-digit"
        hour="2-digit"
      />
    </>
  );
}

LastActivity.propTypes = {
  date: PropTypes.number.isRequired,
  spaceId: PropTypes.string.isRequired,
};

export default LastActivity;
