import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { DRAFTS } from 'DataLayer/endpoints';

export const post = async ({ workspaceId, channelId, value }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.post(
    DRAFTS,
    { workspaceId, channelId, value },
    config
  );

  return data;
};
