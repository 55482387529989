import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Popup } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { useDeleteDraft } from 'Hooks/data/drafts/useDrafts';

import WeTeamIcon from 'Components/Icons/WeTeamIcon';
import { DeleteIcon } from 'Components/Icons/WeTeamIcon';

function RemoveDraftButton({ id }) {
  const deleteDraftMutation = useDeleteDraft();

  const onShowDialog = event => {
    deleteDraftMutation.mutateAsync({ id });
  };

  if (deleteDraftMutation.isLoading) {
    return <Loader size="small" inline active />;
  }

  return (
    <Popup
      inverted
      size="mini"
      on="hover"
      position="left center"
      trigger={<WeTeamIcon onClick={onShowDialog} Icon={DeleteIcon} />}
    >
      <FormattedMessage id="RemoveDraftButton" />
    </Popup>
  );
}

RemoveDraftButton.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RemoveDraftButton;
