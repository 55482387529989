import React from 'react';
import PropTypes from 'prop-types';

import styles from './FullScreenPopup.module.css';
import classNames from 'classnames/bind';

const FullScreenPopup = ({ children, header, wide = false }) => {
  return (
    <div
      className={classNames(styles.fullScreenPopup, {
        [styles.wide]: wide,
      })}
    >
      <div className={styles.header}>
        <strong>{header}</strong>
      </div>
      <div className={styles.scroller}>{children}</div>
    </div>
  );
};

FullScreenPopup.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
};

export default FullScreenPopup;
