import axios from 'axios';
import {
  MEETINGS_INSTANT,
  MEETINGS_SCHEDULED,
  getUnverifiedUserRequestAllowedConfig,
  MEETINGS,
  getStartScheduledMeetingUrl,
  getInitScheduledMeetingUrl,
} from 'DataLayer/endpoints';
import { getOtixoBackendApiUrl } from '../../config/config';

export const createMeetingUser = ({
  userId,
  firstName,
  lastName,
  email,
  avatarUrl,
  color,
  channelId,
  invite,
}) => ({
  userId,
  firstName,
  lastName,
  email,
  avatarUrl,
  color,
  channelId,
  invite,
});

export function post({
  callerUserId,
  channelName = '',
  workspaceId = '',
  workspaceName = '',
  users,
}) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(
      MEETINGS,
      {
        callerUserId,
        channelName,
        workspaceName,
        workspaceId,
        users,
      },
      config
    )
    .then(response => response.data);
}

export function postInstant(roomName, displayName) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(MEETINGS_INSTANT, { roomName, displayName }, config)
    .then(response => response.data);
}

export function postScheduled(
  roomName,
  host,
  hostName,
  guests,
  language,
  template,
  meetingStartsAt,
  meetingEndsAt,
  timezone,
  recapchaToken
) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(
      MEETINGS_SCHEDULED,
      {
        roomName,
        host,
        hostName,
        guests,
        language,
        template,
        meetingStartsAt,
        meetingEndsAt,
        timezone,
        recapchaToken,
      },
      config
    )
    .then(response => response.data);
}

export function startScheduled(meetingId, token) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(getStartScheduledMeetingUrl(meetingId, token), {}, config)
    .then(response => response.data);
}

export function initScheduled(meetingId, token) {
  const config = getUnverifiedUserRequestAllowedConfig();
  config.baseURL = getOtixoBackendApiUrl();

  return axios
    .post(getInitScheduledMeetingUrl(meetingId, token), {}, config)
    .then(response => response.data);
}
