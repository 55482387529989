import React from 'react';
import { Popup, Label } from 'semantic-ui-react';

import DraftsList from './DraftsList';

import { FormattedMessage } from 'react-intl';
import FullScreenPopup from 'Components/Layouts/SidebarLayout/FullScreenPopup';
import WeTeamIcon, { MessageDotsIcon } from 'Components/Icons/WeTeamIcon';
import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import { useIsBackupMode } from 'Hooks/backup/useIsBackupMode';
import {
  useGetDrafts,
  useWatchDraftChangesFromWebSocket,
} from 'Hooks/data/drafts/useDrafts';

const AllDraftsButton = () => {
  const { data, refetch } = useGetDrafts();

  useWatchDraftChangesFromWebSocket();

  const onButtonClick = event => {
    refetch();
  };
  const isBackupMode = useIsBackupMode();

  return (
    <Popup
      basic
      disabled={isBackupMode}
      flowing
      on="click"
      position="bottom right"
      onOpen={onButtonClick}
      trigger={
        <span>
          <Popup
            on="hover"
            inverted
            disabled={isBackupMode}
            size="mini"
            position="bottom right"
            trigger={
              <span className="position-relative">
                <DisableInBackupMode>
                  <WeTeamIcon Icon={MessageDotsIcon} />
                </DisableInBackupMode>
                {data.length > 0 && (
                  <Label
                    color="blue"
                    floating
                    circular
                    size="mini"
                    style={{
                      top: -16,
                    }}
                  >
                    {data.length}
                  </Label>
                )}
              </span>
            }
          >
            <FormattedMessage id="AllDraftsButton.all-starred" />
          </Popup>
        </span>
      }
      style={{
        paddingRight: 0,
      }}
    >
      <FullScreenPopup
        header={<FormattedMessage id="AllDraftsButton.all-starred" />}
        wide
      >
        <p>
          <FormattedMessage id="DraftsList.EmptyMessage" />
        </p>
        <DraftsList drafts={data} />
      </FullScreenPopup>
    </Popup>
  );
};

AllDraftsButton.propTypes = {};

export default AllDraftsButton;
