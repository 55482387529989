import { FormattedMessage } from 'react-intl';

/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-find-dom-node */
import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Loader, Icon, Transition } from 'semantic-ui-react';

import { isScrollAtBottom, isScrollAtTop } from 'Helpers/Element/scroll';

import AddSpaceCommentContainer from '../../Comment/AddSpaceCommentContainer';
import MessageLimitReached from './MessageLimitReached';

import ChatMigrateMessage from '../../Migration/Chat/ChatMigrateMessage';
import TypingIndicator from '../../TypingIndicator/TypingIndicator';
import PendingConversation from './PendingConversation';
import { withIsApplicationActive } from 'Components/Utilities/withIsApplicationActive';
import ChatArrow from 'Components/Icons/WeTeam/Chat-Arrow.svg';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';
import StartMeetingButton from './StartMeetingButton';
import AudioPlayer from 'Components/AudioPlayer/AudioPlayer';
import MessageSelectionBanner from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionBanner';
import { MessageSelectionProvider } from 'Components/Chat/SpaceChat/MessageSelection/MessageSelectionContext';

import styles from './Comments.module.css';

class Comments extends React.Component {
  state = { showScrollToBottomButton: false };

  componentDidMount() {
    if (this.props.comments.length > 0) {
      // if this chat has comments, it was before, so let's try and scroll to the previous recorded position
      if (this.props.goToNotificationId) {
        this.scrollToComment(this.props.goToNotificationId);
      } else if (this.props.scrollTo) {
        this.scrollToPosition(this.props.scrollTo);
      } else {
        this.scrollToBottom();
      }
    } else {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    // we are leaving the chat
    this.markAsRead(this.props.spaceId);
  }

  /* eslint-disable react/sort-comp */
  getSnapshotBeforeUpdate(prevProps) {
    if (
      this.props.spaceId !== prevProps.spaceId ||
      this.props.resourceId !== prevProps.resourceId
    ) {
      // we have just switched channels
      this.markAsRead(prevProps.spaceId);
    }

    if (!prevProps.loadingNewer && this.props.loadingNewer) {
      return { scrollToBottom: true };
    }
    /* eslint-enable react/sort-comp */
    if (prevProps.comments.length === this.props.comments.length) {
      return null;
    }
    const { messageList } = this.refs;

    if (prevProps.comments.length === 0 && this.props.comments.length > 0) {
      // TODO: Scroll to jumpToNotificationId here
      if (this.props.goToNotificationId) {
        return { scrollToComment: this.props.goToNotificationId };
      }
      // when the first set of comments arrive, scroll to the bottom
      return { scrollToBottom: true };
    } else if (this.props.loadingOlder) {
      // if the user scrolled to the top to load more items, we should maintain the scroll position
      return {
        previousScrollPosition:
          messageList.scrollHeight - messageList.scrollTop,
      };
    } else if (this.props.loadingNewer) {
      // if the container is already at the bottom, ensure we remain at the bottom
      return null;
    } else if (isScrollAtBottom(messageList)) {
      // if the container is already at the bottom, ensure we remain at the bottom
      return { scrollToBottom: true };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.spaceId !== prevProps.spaceId ||
      this.props.resourceId !== prevProps.resourceId
    ) {
      // scroll to the bottom when we switch to a new space
      if (this.props.goToNotificationId) {
        this.scrollToComment(this.props.goToNotificationId);
      } else {
        this.scrollToBottom();
      }
    } else if (
      this.props.isApplicationActive !== prevProps.isApplicationActive &&
      this.props.isApplicationActive
    ) {
      // if the user refocusses the app, get the latest messages incase any were missed while the app was in the background
      this.props.fetchLatest();
    } else if (snapshot) {
      const { scrollToBottom, previousScrollPosition, scrollToComment } =
        snapshot;
      if (scrollToComment) {
        this.scrollToComment(this.props.goToNotificationId);
      } else if (scrollToBottom) {
        this.scrollToBottom();
      } else if (previousScrollPosition) {
        this.refs.messageList.scrollTop =
          this.refs.messageList.scrollHeight - previousScrollPosition;
      }
    }
  }

  /*
    When opening the Emoji panel in the AddComment component, we
    need to ensure the message showing at the bottom of the Chat
    stays in view. If we don't do anything, the messages will be
    scrolled out of view.

    Store the scroll information of the Chat window so we can restore
    the scroll position after the emoji panel is opened
  */
  onEmojiPanelChanging = () => {
    const { messageList } = this.refs;
    this.diff = 0;
    const emojiHeight = Math.abs(
      this.previousClientHeight - this.newClientHeight
    );
    this.previousClientHeight = messageList.clientHeight;
    if (
      messageList.scrollTop + emojiHeight >
      messageList.scrollHeight - messageList.clientHeight
    ) {
      this.diff =
        messageList.scrollTop +
        emojiHeight -
        (messageList.scrollHeight - messageList.clientHeight);
    }
  };
  /*
  Restore the scroll position after the emoji panel is opened and closed
  */
  onEmojiPanelChanged = open => {
    const { messageList } = this.refs;
    this.newClientHeight = messageList.clientHeight;
    if (open) {
      messageList.scrollTop += this.previousClientHeight - this.newClientHeight;
    } else {
      messageList.scrollTop -=
        this.newClientHeight - this.previousClientHeight - this.diff;
    }
  };

  onCommentAdded = () => {
    this.scrollToBottom();
  };

  onScroll = () => {
    if (!this.refs.messageList) {
      return;
    }

    if (isScrollAtTop(this.refs.messageList)) {
      this.props.onLoadOlder();
    }

    const isAtBottom = isScrollAtBottom(this.refs.messageList);
    this.setState({ showScrollToBottomButton: !isAtBottom });

    if (isAtBottom) {
      this.props.onLoadNewer();
      this.markAsRead(this.props.spaceId);
    }

    this.props.onScroll(this.refs.messageList.scrollTop);
  };

  /*
    mark the chat as read if the user has scrolled to the bottom and the window is focussed
  */
  markAsRead = spaceId => {
    if (
      isScrollAtBottom(this.refs.messageList) &&
      this.props.isApplicationActive
    ) {
      this.props.markAsRead(spaceId);
    }
  };

  resetRefs() {
    this.myRefs = [];
  }

  scrollToComment(id) {
    const { messageList } = this.refs;
    if (this.myRefs[id] && this.myRefs[id].current) {
      // Position element in the middle of our scroll container
      const el = this.myRefs[id].current;
      let offset;

      if (el.clientHeight < messageList.clientHeight) {
        offset =
          el.offsetTop - (messageList.clientHeight / 2 - el.clientHeight / 2);
      } else {
        offset = el.offsetTop;
      }

      this.refs.messageList.scrollTop = offset;
    }
  }

  scrollToBottom = () => {
    const { messageList } = this.refs;
    if (messageList) {
      const scrollHeight = messageList.scrollHeight;
      const height = messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  scrollToPosition = position => {
    const { messageList } = this.refs;
    if (messageList) {
      messageList.scrollTop = position;
    }
  };

  getRef(id) {
    if (this.myRefs[id]) {
      return this.myRefs[id];
    }
    const ref = React.createRef();
    this.myRefs[id] = ref;
    return ref;
  }

  myRefs = [];

  render() {
    const {
      comments,
      spaceId,
      workspaceId,
      hasNewerMessages,
      hasOlderMessages,
      loadingOlder,
      resourceId,
      autoFocus,
      canAddComment,
      hasHiddenMessages,
      onJumpToPresent,
      replyComment,
      onRemoveReply,
      onAddComment,
      lastUserComment,
      canRecordVoiceMessage,
      showShareIcon,
    } = this.props;

    return (
      <MessageSelectionProvider channelId={spaceId} workspaceId={workspaceId}>
        <div className="flex column flex-auto position-relative overflow-auto">
          <MessageSelectionBanner />
          {canRecordVoiceMessage && (
            <ErrorBoundary>
              <AudioPlayer />
            </ErrorBoundary>
          )}
          <ChatMigrateMessage spaceId={spaceId} />
          <PendingConversation spaceId={spaceId} />
          {hasHiddenMessages && (
            <ErrorBoundary>
              <MessageLimitReached spaceId={spaceId} />
            </ErrorBoundary>
          )}
          <div className="flex flex-auto position-relative min-height-0">
            <Transition
              visible={this.state.showScrollToBottomButton}
              animation="scale"
              duration={200}
            >
              <div
                className={styles.scrollToBottomButton}
                onClick={this.scrollToBottom}
              >
                <img src={ChatArrow} alt="scroll to bottom" />
              </div>
            </Transition>
            <div
              className="flex-auto overflow-auto pb-3"
              ref="messageList"
              onScroll={debounce(this.onScroll, 500)}
            >
              {hasOlderMessages && loadingOlder && (
                <div
                  className="flex justify-content-center"
                  style={{ margin: '10px 0px' }}
                >
                  <Loader active inline />
                </div>
              )}
              {/* commentNodes */}
              {comments.map((comment, index) => {
                const ref = this.getRef(comment.Id);
                return this.props.renderMessage(
                  comment,
                  comments[index - 1],
                  ref
                );
              })}
              {hasNewerMessages && (
                <div
                  className="flex justify-content-center"
                  style={{ margin: '10px 0px' }}
                >
                  <Loader active inline />
                </div>
              )}
            </div>
          </div>
          {hasNewerMessages && (
            <div
              className={styles.jumpToPresentButton}
              onClick={onJumpToPresent}
            >
              <FormattedMessage id="Comments.you-are-viewing-older-messages-jump-to-present" />
              <Icon name="arrow down" />
            </div>
          )}
          {workspaceId && spaceId && (
            <StartMeetingButton channelId={spaceId} workspaceId={workspaceId} />
          )}
          <TypingIndicator spaceId={spaceId} />
          {spaceId && (
            <AddSpaceCommentContainer
              className={styles.addCommentContainer}
              canAddComment={canAddComment}
              onAddComment={onAddComment}
              onCommentAdded={this.onCommentAdded}
              spaceId={spaceId}
              workspaceId={workspaceId}
              resourceId={resourceId}
              onEmojiPanelChanging={this.onEmojiPanelChanging}
              onEmojiPanelChanged={this.onEmojiPanelChanged}
              lastUserComment={lastUserComment}
              autoFocus={autoFocus}
              replyComment={replyComment}
              onRemoveReply={onRemoveReply}
              canRecordVoiceMessage={canRecordVoiceMessage}
              showShareIcon={showShareIcon}
              removeDraftWhenValueEmpty
            />
          )}
        </div>
      </MessageSelectionProvider>
    );
  }
}
Comments.propTypes = {
  canAddComment: PropTypes.bool,
  comments: PropTypes.array.isRequired,
  renderMessage: PropTypes.func.isRequired,
  fetchLatest: PropTypes.func,
  goToNotificationId: PropTypes.string,
  hasHiddenMessages: PropTypes.bool,
  hasOlderMessages: PropTypes.bool,
  hasNewerMessages: PropTypes.bool,
  loadingOlder: PropTypes.bool,
  loadingNewer: PropTypes.bool,
  markAsRead: PropTypes.func,
  onAddComment: PropTypes.func,
  onJumpToPresent: PropTypes.func,
  onLoadOlder: PropTypes.func,
  onLoadNewer: PropTypes.func,
  resourceId: PropTypes.string,
  spaceId: PropTypes.string,
  workspaceId: PropTypes.string,
  isApplicationActive: PropTypes.bool.isRequired,
  lastUserComment: PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string,
  }),
  autoFocus: PropTypes.bool,
  replyComment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
  }),
  onRemoveReply: PropTypes.func,
  scrollTo: PropTypes.number,
  onScroll: PropTypes.func,
  canRecordVoiceMessage: PropTypes.bool,
  showShareIcon: PropTypes.bool,
};

Comments.defaultProps = {
  canAddComment: true,
  fetchLatest: () => {},
  markAsRead: () => {},
  onLoadNewer: () => {},
  onLoadOlder: () => {},
  onScroll: () => {},
};

export default withIsApplicationActive(Comments);
