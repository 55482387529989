import React from 'react';
import PropTypes from 'prop-types';
import { Message, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import classNames from 'classnames/bind';

import { getSpaceLink } from 'pages';

import addSpaceComment from 'store/Comments/addSpaceComment';

import { useWorkspace } from 'Hooks/data/workspace/useWorkspace';
import { useChannel } from 'Hooks/data/channel/useChannel';

import AddSpaceCommentContainer from 'Components/Comment/AddSpaceCommentContainer';
import RemoveDraftButton from 'Components/Drafts/RemoveDraftButton';

import styles from './Draft.module.css';
import LinkButton from 'Components/Elements/LinkButton';

function Draft({ draft, router: { push } }) {
  const dispatch = useDispatch();
  const workspace = useWorkspace(draft.workspaceId);
  const { channel, error } = useChannel(draft.channelId, true);

  const onClick = () => {
    // close all notes dialog
    document.documentElement.click();
    push(getSpaceLink(draft.channelId));
  };

  const onAddComment = async message => {
    await dispatch(addSpaceComment(draft.channelId, null, message));
    onClick(draft.channelId);
  };

  return (
    <Segment key={draft.id}>
      <div className="flex justify-content-space-between mb-3">
        <strong>
          {channel?.Name && (
            <>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              {workspace.Name}&nbsp;→&nbsp;
              <LinkButton onClick={onClick}>{channel.Name}</LinkButton>
            </>
          )}
        </strong>
        <RemoveDraftButton id={draft.id} />
      </div>
      {error ? (
        <blockquote className={classNames(styles.text, 'mb-2')}>
          {draft.value}
        </blockquote>
      ) : (
        <AddSpaceCommentContainer
          onAddComment={onAddComment}
          spaceId={draft.channelId}
          workspaceId={draft.workspaceId}
          autoFocus={false}
          emojiEnabled={false}
          showLeftIcons={false}
          removeDraftWhenValueEmpty={false}
        />
      )}
      <div>
        <small>
          <em>
            <FormattedDate
              value={draft.updatedAt}
              year="numeric"
              month="short"
              day="2-digit"
            />
            &nbsp;
            <FormattedTime
              value={draft.updatedAt}
              updateInterval={false}
              minute="2-digit"
              hour="2-digit"
            />
          </em>
        </small>
      </div>
      {error && (
        <Message error className="mt-2">
          <FormattedMessage id="DraftLocation.ChannelNotFound" />
        </Message>
      )}
    </Segment>
  );
}

Draft.propTypes = {
  draft: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Draft);
