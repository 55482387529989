import { getbyWorkspaceId } from 'DataLayer/Spaces/get';
import { setSpacesRequestInProgress } from '../Requests/setRequestInProgress';

import { spacesLoaded } from './actions';
import { getSpacesInProgress } from 'store/Requests/selectors';

const loadSpaces = workspaceId => async (dispatch, getState) => {
  try {
    const state = getState();
    if (getSpacesInProgress(state, workspaceId)) {
      return Promise.resolve([]);
    }

    dispatch(setSpacesRequestInProgress(true, workspaceId));

    const { cachedChannels, fetchedPromise } =
      await getbyWorkspaceId(workspaceId);
    if (cachedChannels) {
      dispatch(spacesLoaded(cachedChannels, workspaceId));
    }
    const networkChannels = await fetchedPromise;
    dispatch(spacesLoaded(networkChannels, workspaceId));
    dispatch(setSpacesRequestInProgress(false, workspaceId));
    return networkChannels;
  } catch (e) {
    dispatch(setSpacesRequestInProgress(false, workspaceId));
    throw e;
  }
};

export default loadSpaces;
