import React from 'react';
import PropTypes from 'prop-types';
import { Label, Popup } from 'semantic-ui-react';

import { useDraftsByWorkspaceId } from 'Hooks/data/drafts/useDrafts';
import { FormattedMessage } from 'react-intl';

const WorkSpaceDraftIcon = ({ workspaceId }) => {
  const drafts = useDraftsByWorkspaceId(workspaceId);
  if (!drafts.length) {
    return null;
  }
  return (
    <Popup
      inverted
      size="mini"
      on="hover"
      position="right center"
      trigger={
        <Label empty circular color="green" size="tiny" floating={false} />
      }
    >
      <FormattedMessage
        id="WorkspaceDraft"
        values={{ number: drafts.length }}
      />
    </Popup>
  );
};

WorkSpaceDraftIcon.propTypes = {
  workspaceId: PropTypes.string.isRequired,
};

export default WorkSpaceDraftIcon;
