import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getDeleteDraftUrl } from 'DataLayer/endpoints';

export const deleteById = async ({ id }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.delete(getDeleteDraftUrl(id), config);

  return data;
};
