import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { getOtixoEnvironment, isOtixoEnvProduction } from 'config/config';
import { isNodeEnvDevelopment } from 'config/config';

export const init = () => {
  if (isNodeEnvDevelopment()) {
    console.debug('Sentry excluded in development');
  } else {
    console.debug('Sentry running');
    Sentry.init({
      dsn: 'https://5cdc21b74c5147b98b6dd4f844f51872@o37189.ingest.sentry.io/5597062',
      release: process.env.REACT_APP_VERSION,
      environment: getOtixoEnvironment(),
      integrations: [
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      //integrations: [new Integrations.BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: isOtixoEnvProduction() ? 0.2 : 1.0,
      normalizeDepth: 10, // Or however deep you want your state context to be.
      beforeSend: (event, hint) => {
        try {
          if (shouldIgnoreEvent(event)) {
            return null;
          }
        } catch (error) {
          return event;
        }

        return event;
      },
    });
  }
};

const SENTRY_TYPES_TO_IGNORE = [
  'UnhandledRejection',
  'Request aborted',
  'DatabaseClosedError',
];
const SENTRY_VALUES_TO_IGNORE = [
  'Non-Error exception captured with keys: code, message',
  'Non-Error promise',
  'timeout of 0ms exceeded',
  'Request aborted',
  'Request failed with status code',
  'Network Error', // these are flooding Sentry with Status Code 0, probably due to user leaving/redirecting while requests are in progress
  'ResizeObserver loop limit exceeded', // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/3,
  'Request failed with status code 401',
  'The play() request was interrupted by a new load request.',
  'Loading CSS chunk',
  'Object captured as exception with keys: code, message',
  'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
  'Non-Error promise rejection captured with value: undefined',
  'play() failed because the user',
  'The operation was aborted',
  'Unexpected token h in JSON at position 0',
  'Unexpected token T in JSON at position 0',
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
];

export const shouldIgnoreEvent = event => {
  try {
    if (SENTRY_TYPES_TO_IGNORE.includes(event.exception.values[0].type)) {
      return true;
    }
    if (
      SENTRY_VALUES_TO_IGNORE.some(value =>
        event.exception.values[0].value.includes(value)
      )
    ) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const captureException = ex => Sentry.captureException(ex);
