import { sortAlphabetical } from 'DataLayer/Adapters/sort';
import { createSelector } from 'reselect';

export function getAdapterById(state, id) {
  return state.adapters.find(adapter => id === adapter.Id);
}

const getAdaptersInternal = state => state.adapters;

export const getAdapters = createSelector(
  [getAdaptersInternal],
  adapters => adapters
);

const getCreatableAdapters = createSelector([getAdaptersInternal], adapters =>
  adapters.filter(adapter => adapter.CanCreate)
);

const RETIRED_ADAPTERS = [
  '590203c8-850a-4f2c-bcf7-0148c85e157f', //4shared
  'alfresco',
  'amazon',
  '9522bc7c-f183-43ba-9b47-27514c6f0c0b', //cubby
  '0452e3e0-536d-43a8-a70c-f676fabc7c17', //Drive on Web
  'facebook',
  'huddle',
  'magenta',
  '9dd4a262-46c9-4a00-90c7-8dddf3fa7ffd', //TrendMicro
  '6339ba95-43d2-43a2-97c0-726ca6a0ff6a', // HiDrive
  'meganz',
];

export const getCreatableAdaptersSortedPopular = createSelector(
  [getCreatableAdapters],
  adapters =>
    adapters
      .filter(adapter => !RETIRED_ADAPTERS.includes(adapter.Id))
      .sort(sortAlphabetical)
);
