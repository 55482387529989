import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  useDeleteDraft,
  useDraftByChannelId,
  useSaveDraft,
  useUpdateDraft,
} from 'Hooks/data/drafts/useDrafts';

import { getChatSendMessageOnEnter } from 'store/User/selectors';

import LazyWrapper from '../Loading/LazyWrapper';

const AddComment = React.lazy(
  () => import('./AddComment' /* webpackChunkName: "AddComment" */)
);

const AddCommentLazy = ({
  spaceId,
  resourceId,
  workspaceId,
  removeDraftWhenValueEmpty,
  ...props
}) => {
  const draft = useDraftByChannelId(spaceId);

  const updateDraftMutation = useUpdateDraft();
  const saveDraftMutation = useSaveDraft();
  const deleteDraftMutation = useDeleteDraft();

  const onDraftRemoved = async value => {
    if (draft) {
      await deleteDraftMutation.mutateAsync({ id: draft.id });
    }
  };

  const onDraftUpdated = async value => {
    if (resourceId) {
      return;
    }

    if (draft && !value.length && removeDraftWhenValueEmpty) {
      await deleteDraftMutation.mutateAsync({ id: draft.id });
      return;
    }

    if (draft && draft.value !== value) {
      await updateDraftMutation.mutateAsync({ id: draft.id, value });
      return;
    }

    if (!draft && value.length) {
      await saveDraftMutation.mutateAsync({
        workspaceId,
        channelId: spaceId,
        value,
      });
      return;
    }
  };

  const finalDraft = useMemo(() => {
    if (resourceId) {
      return '';
    }
    return draft?.value;
  }, [draft?.value, resourceId]);

  return (
    <LazyWrapper hideLoader>
      <AddComment
        {...props}
        spaceId={spaceId}
        resourceId={resourceId}
        draft={finalDraft}
        onDraftUpdated={onDraftUpdated}
        onDraftRemoved={onDraftRemoved}
      />
    </LazyWrapper>
  );
};

AddCommentLazy.propTypes = {
  spaceId: PropTypes.string.isRequired,
  resourceId: PropTypes.string,
  workspaceId: PropTypes.string,
  removeDraftWhenValueEmpty: PropTypes.bool,
  sendMessageOnEnter: PropTypes.bool,
};

export default connect(state => ({
  sendMessageOnEnter: getChatSendMessageOnEnter(state),
}))(AddCommentLazy);
