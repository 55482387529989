import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import styles from './PreviewError.module.css';

const PreviewError = ({ height, className, ...props }) => (
  <div className={classNames(styles.container, className)} {...props}>
    <FormattedMessage id="PreviewError.Message" />
  </div>
);

PreviewError.propTypes = {
  height: PropTypes.number,
};

export default PreviewError;
