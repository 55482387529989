import React from 'react';
import PropTypes from 'prop-types';

import DisableInBackupMode from 'Components/BackupMode/DisableInBackupMode';
import RightSidebar from 'Components/RightSidebar/RightSidebar';
import WeTeamIcon, { MoneyCheckPenIcon } from 'Components/Icons/WeTeamIcon';
import { useNotesByChannelId } from 'Hooks/data/notes/useNotes';

function NotesButtonForWorkspaces({
  channelId,
  isActive,
  onClick,
  stars,
  onLoadStarred,
}) {
  const { data } = useNotesByChannelId(channelId);

  const finalData = data || [];

  return (
    <DisableInBackupMode>
      <RightSidebar.Button
        openTooltip="ShowNotes.Hide"
        closeTooltip="ShowNotes.Show"
        open={isActive}
        onClick={onClick}
      >
        <WeTeamIcon active={isActive} Icon={MoneyCheckPenIcon} />
        <small>{finalData.length > 0 && finalData.length}</small>
      </RightSidebar.Button>
    </DisableInBackupMode>
  );
}

NotesButtonForWorkspaces.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotesButtonForWorkspaces;
