import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import { getWorkspaceLink } from 'pages';

import WorkspaceIcon from '../../Workspace/WorkspaceIcon';

import NavButton from './NavButton';
import { makeGetUnreadNotificationsCountByWorkspaceId } from 'store/Notifications/selectors';
import NotificationBadge from '../../Badges/NotificationBadge';
import ElementPositionTracker from '../../ScrollPositionTracker/ElementPositionTracker';

import styles from './WorkSpaceButton.module.scss';
import MeetingBeaconByWorkspaceId from '../../Meeting/MeetingBeaconByWorkspaceId';
import UnreadMessagesBadge from 'Components/AppNavigation/Components/UnreadMessagesBadge';
import WorkSpaceDraft from 'Components/AppNavigation/Components/WorkSpaceDraft';
import WorkSpaceDraftIcon from 'Components/AppNavigation/Components/WorkSpaceDraftIcon';

const WorkSpaceButton = ({
  workspace,
  active,
  open,
  canSort,
  numberOfNotifications,
  onWorkSpaceSelected,
  router,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (active) {
      requestAnimationFrame(() => {
        ref.current?.scrollIntoView?.({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      });
    }
  }, [active, numberOfNotifications, open, canSort]);

  const onClick = () => {
    onWorkSpaceSelected();
    router.push(getWorkspaceLink(workspace.Id, workspace.ProvisionalSpace));
  };

  const renderButton = () => {
    return (
      <div ref={ref} className={`${styles.workspaceButton} py-1`}>
        {/*
          placed here so not effected by opacity
        */}

        <NavButton
          selected={active}
          onClick={onClick}
          icon={
            <Popup
              position="right center"
              inverted
              style={{
                opacity: open ? 0 : 1, // hide when open
              }}
              trigger={
                <span className={styles.icon}>
                  <WorkspaceIcon
                    workspace={workspace}
                    canMove={canSort}
                    size={44}
                  />
                  <span className={styles.draftIcon}>
                    <WorkSpaceDraftIcon workspaceId={workspace.Id} />
                  </span>
                  {!open && (
                    <NotificationBadge
                      className={styles.unreadBadge}
                      count={numberOfNotifications}
                      floating={false}
                    />
                  )}
                  <span className={styles.meetingBeacon}>
                    <MeetingBeaconByWorkspaceId
                      width={16}
                      height={16}
                      workspaceId={workspace.Id}
                    />
                  </span>
                </span>
              }
              content={workspace.Name}
              size="mini"
            />
          }
        >
          <div className={styles.info}>
            <span className={styles.name} title={workspace.Name}>
              {workspace.Name}
              <small
                className={`${styles.email} text-ellipsis`}
                title={workspace.MembershipMail}
              >
                {workspace.MembershipMail}
              </small>
              <WorkSpaceDraft workspaceId={workspace.Id} />
            </span>
            <span className={styles.badge}>
              <UnreadMessagesBadge
                workspaceId={workspace.Id}
                numberOfNotifications={numberOfNotifications}
              />
            </span>
          </div>
        </NavButton>
      </div>
    );
  };

  if (numberOfNotifications === 0) {
    return renderButton();
  }

  return (
    <ElementPositionTracker id={workspace.Id} number={numberOfNotifications}>
      {renderButton()}
    </ElementPositionTracker>
  );
};

WorkSpaceButton.propTypes = {
  workspace: PropTypes.object.isRequired,
  active: PropTypes.bool,
  onWorkSpaceSelected: PropTypes.func,
  open: PropTypes.bool,
  canSort: PropTypes.bool,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  numberOfNotifications: PropTypes.number.isRequired,
};

export default connect(() => {
  const getUnreadNotificationsCountByWorkspaceId =
    makeGetUnreadNotificationsCountByWorkspaceId();
  return (state, props) => {
    return {
      numberOfNotifications: getUnreadNotificationsCountByWorkspaceId(
        state,
        props.workspace.Id
      ),
    };
  };
})(withRouter(WorkSpaceButton));
