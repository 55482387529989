import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { getPutDraftUrl } from 'DataLayer/endpoints';

export const putById = async ({ id, value }) => {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.put(getPutDraftUrl(id), { value }, config);

  return data;
};
