import PropTypes from 'prop-types';
import { useState } from 'react';
import { Select, Form, Checkbox } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { sortBy, uniqBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import { useContacts } from 'Hooks/data/contacts/useContacts';

import Avatar from 'Components/Collaborators/Avatar';
import SpaceIcon from 'Components/Spaces/SpaceIcon';

const style = { marginRight: 10 };

export const CreateMembersMeetingNew = ({
  chats,
  defaultWorkspaceId,
  onWorkspaceSelected,
  onSelectAllMembers,
  showSelectAllMembersOption,
  showLoader,
  selectedChats,
  onSelectedChatsChange,
  allChannelMembersSelected,
  intl,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const onSearchChange = (event, { searchQuery }) => {
    setSearchQuery(searchQuery);
  };

  const { searchedContacts, isFetching } = useContacts(
    debouncedSearchQuery,
    defaultWorkspaceId
  );

  const chatsFinal = chats.concat(searchedContacts);

  const chatsFilteredUnique = uniqBy(chatsFinal, 'collaboratorUserId');

  return (
    <>
      <Form.Field>
        <label htmlFor="members">
          <FormattedMessage id="CreateMembersMeeting.Members" />
        </label>
        <Select
          selectOnBlur={false}
          search
          loading={isFetching}
          multiple
          name="members"
          fluid
          placeholder={intl.formatMessage({
            id: 'CreateMembersMeetingNew.placeholder',
          })}
          noResultsMessage={
            isFetching
              ? null
              : intl.formatMessage({
                  id: 'noResultsMessage',
                })
          }
          onSearchChange={onSearchChange}
          onChange={(event, data) => {
            onSelectedChatsChange(
              data.value.map(value =>
                /**
                 * prioritize the current teamspace
                 */
                sortBy(chatsFinal, chat =>
                  chat.WorkspaceId === defaultWorkspaceId ? 0 : 1
                ).find(chat => chat.collaboratorUserId === value)
              )
            );
          }}
          options={chatsFilteredUnique.map(chat => ({
            key: chat.collaboratorUserId,
            text: chat.Name,
            value: chat.collaboratorUserId,
            chat,
            content: (
              <div className="flex align-items-center">
                {chat.fromApi ? (
                  <Avatar
                    avatarUrl={chat.AvatarUrl}
                    color={chat.Color}
                    email={chat.Email}
                    firstName={chat.FirstName}
                    lastName={chat.LastName}
                    presence={chat.Presence}
                    showStatus
                    width={30}
                    height={30}
                    style={style}
                  />
                ) : (
                  <SpaceIcon space={chat} width={30} height={30} showStatus />
                )}
                <span className="mx-1">{chat.Name}</span>
              </div>
            ),
          }))}
          renderLabel={item => {
            return {
              content: (
                <span>
                  {item.chat.fromApi ? (
                    <Avatar
                      avatarUrl={item.chat.AvatarUrl}
                      color={item.chat.Color}
                      email={item.chat.Email}
                      firstName={item.chat.FirstName}
                      lastName={item.chat.LastName}
                      presence={item.chat.Presence}
                      showStatus
                      width={26}
                      height={26}
                    />
                  ) : (
                    <SpaceIcon
                      space={item.chat}
                      width={26}
                      height={26}
                      showStatus
                    />
                  )}
                  {item.chat.Name}
                </span>
              ),
            };
          }}
          value={selectedChats
            .filter(chat => chat)
            .map(chat => chat.collaboratorUserId)}
        />
        {showSelectAllMembersOption && (
          <Checkbox
            size="mini"
            checked={allChannelMembersSelected}
            onChange={onSelectAllMembers}
            className="mt-2"
            label={{
              children: (
                <FormattedMessage id="CreateMembersMeeting.SelectAllChannelMembers" />
              ),
            }}
          />
        )}
      </Form.Field>
    </>
  );
};

CreateMembersMeetingNew.propTypes = {
  selectedChats: PropTypes.array.isRequired,
  defaultWorkspaceId: PropTypes.string.isRequired,
  onSelectedChatsChange: PropTypes.func.isRequired,
  allChannelMembersSelected: PropTypes.bool,
  chats: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CreateMembersMeetingNew);
